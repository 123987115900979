<template>
  <div class="pages">
    <!-- <div class="header">
      <h1 class="text">北斗·UU</h1>
    </div> -->
    <title >{{ title ||"登 录"}}</title>
    <div class="login_container">
      <div class="login_box">
        <router-view ></router-view>
      </div>
    </div>
    <div class="title">
      <img v-if="Logo" :src="Logo" alt="" />
      <h1 class="hcqStyle6" v-if="title">{{ title }}</h1>
    </div>
    <div class="Footer" v-html="html"></div>
  </div>
</template>

<script>
import { GetCurPlatformPersonalization } from "@/network/api";
export default {
  data() {
    return {
      Logo: "",
      title: "",
      src: "",
      html:''
    };
  },
  created() {
    GetCurPlatformPersonalization().then((res) => {
      if (res.code == 1) { 
        var link =
          document.querySelector('link[rel*="icon"]') ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = res?.data?.Logo;
        this.Logo = res.data.Logo;
        this.title = res.data.MaxTitle;
        // if (this.title) {
        //   document.title = "登 录";
        // }
        this.html=res?.data?.CurSystem?.WebsiteFiling||''
      }
    });

    // this.$nextTick(() => {
    this.src = window.location.protocol + "//" + window.location.host + "/#/Login1"; 
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.title {
  text-align: center;
  position: fixed;
  display: flex;
  align-items: center;
  min-height: 60px;
  top: 20px;
  left: 80px;
  img {
    width: 60px;
    margin-right: 20px;
    border-radius: 5px;
  }

  .hcqStyle6 {
    letter-spacing: 0;
    color: #ecf5ff;
    // color: #f5e37c;
    // text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777,
    //   0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333,
    //   0px 8px 7px #001135;
  }
}
.pages {
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 80px;
}
.Footer {
  width: 100%;
  height: 100px;
  text-align: center;
  font-size: 14px;
  color: #999;
  text-align: center;
  position: fixed;
  bottom: 0;
  line-height: normal;
}
.header {
  //   height: 80px;
  text-align: left;
  letter-spacing: 2px;
  padding: 20px 50px;
  color: #1e9fff;
  font-size: 25px;
  font-weight: bold;
}
.text {
  margin: 0;
  // background-image: -webkit-linear-gradient(
  //   left,
  //   blue,
  //   #66ffff 10%,
  //   #f29100 20%,
  //   #fcbd71 30%,
  //   #fdf6ec 40%,
  //   #00ffff 50%,
  //   #ccccff 60%,
  //   #f29100 70%,
  //   #fcbd71 80%,
  //   #66ffff 90%,
  //   blue 100%
  // );
  // -webkit-text-fill-color: transparent; /* 将字体设置成透明色 */
  // -webkit-background-clip: text; /* 裁剪背景图，使文字作为裁剪区域向外裁剪 */
  // -webkit-background-size: 200% 100%;
  // -webkit-animation: masked-animation 4s linear infinite;
  // font-size: 35px;
  font-family: "Times New Roman", Times, serif;

  font-weight: bold;
}
@keyframes masked-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -100% 0;
  }
}
iframe {
  width: 100%;
  height: 100%;
  // border-radius: 10px;
}
.login_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
  // background-image: url("../assets/Login_Bg.jpg") ;
  background: url(../assets/Login_Bg.jpg) no-repeat center center;
  // background-repeat: no-repeat;
  background-size: cover;
}
.login_box {
  width: 350px;
  height: 420px;
  /* background-color: #fff; */
  // background-color: #2e527bb3;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 50%;
  // position: absolute;
  // left: 68%;
  // top: 50%;
  // transform: translate(0%, -50%);
  -moz-box-shadow: 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #fff;
  box-shadow: 0 0 5px #fff;
}
</style>